// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff;
$gray-100: #f8f9ff;
$gray-200: #f4f5fd;
$gray-300: #eeeff8;
$gray-400: #e6e7f1;
$gray-500: #dfe0ea;
$gray-600: #d1d2db;
$gray-700: #a6a6b9;
$gray-800: #757575;
$gray-900: #2d4f77;
$black: #20293a;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-200,
    '200': $gray-300,
    '300': $gray-400,
    '400': $gray-500,
    '500': $gray-600,
    '600': $gray-700,
    '700': $gray-800,
    '800': $gray-900,
    '900': $black
  ),
  $grays
);

$first: #2d4f77;
$second: $black;
$blue: #2d4f77;
$pink-red: #d4494c;
$red: #f83245;
$orange: #f4772e;
$orange-light: #e28033;
$yellow: #ffc926;
$green: #1bc943;
$cyan-green: #6eecc8;
$cyan: #11c5db;
$text-brand: #2d4f77;
$text-body: #20293a;
$text-body-low: #475569;
$text-action-low: #6e758e;
$text-secondary: #4a5567;
$text-caption: #757575;
$text-placeholder: #999999;
$text-info: #01707d;
$text-disabled: #cecece;
$text-success: #047653;
$text-error: #760415;
$text-danger: #c4032a;
$text-warning: #a24501;
$bg-contrast: #f8f8f8;
$bg-info-low: #e2f6fa;
$bg-tertiary: #e0f2fe;
$bg-inverse: #495368;
$bg-success-low: #cfffe7;
$bg-error-low: #ffcfd5;
$bg-warning-low: #fff2ea;
$border-default: #dedede;
$border-info: #68878c;
$border-success: #04986c;
$border-error: #980426;
$border-warning: #f4772e;
$border-action-hi: #495368;
$border-action-low: #c4c4c4;
$bg-tr: #f9fafe;
$inverted-low: #d3d3d3;

// Ces filtres ont été générés depuis https://change-svg-color.vercel.app/
// Important : Commencer le filter par brightness(0%) pour commencer en niveau de gris
// En utilisant #2d4f77 comme hexadécimal :
$text-brand-filter: brightness(0%) invert(24%) sepia(69%) saturate(513%) hue-rotate(172deg)
  brightness(92%) contrast(85%);
// En utilisant #0369A1 comme hexadécimal :
$text-brand-light-filter: brightness(0%) invert(27%) sepia(73%) saturate(1438%) hue-rotate(175deg)
  brightness(96%) contrast(98%);
// En utilisant #CECECE comme hexadécimal :
$text-disabled-filter: brightness(0%) invert(98%) sepia(0%) saturate(1%) hue-rotate(150deg)
  brightness(89%) contrast(84%);

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'pink-red': $pink-red,
    'red': $red,
    'orange': $orange,
    'orange-light': $orange-light,
    'yellow': $yellow,
    'green': $green,
    'cyan-green': $cyan-green,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
    'text-body': $text-body,
    'text-body-low': $text-body-low,
    'text-action-low': $text-action-low,
    'text-secondary': $text-secondary,
    'text-brand': $text-brand,
    'text-brand-filter': $text-brand-filter,
    'text-brand-light-filter': $text-brand-light-filter,
    'text-caption': $text-caption,
    'text-placeholder': $text-placeholder,
    'text-info': $text-info,
    'text-disabled': $text-disabled,
    'text-disabled-filter': $text-disabled-filter,
    'text-success': $text-success,
    'text-error': $text-error,
    'text-danger': $text-danger,
    'text-warning': $text-warning,
    'bg-contrast': $bg-contrast,
    'bg-info-low': $bg-info-low,
    'bg-tertiary': $bg-tertiary,
    'bg-inverse': $bg-inverse,
    'bg-success-low': $bg-success-low,
    'bg-error-low': $bg-error-low,
    'bg-warning-low': $bg-warning-low,
    'border-default': $border-default,
    'border-info': $border-info,
    'border-success': $border-success,
    'border-error': $border-error,
    'border-warning': $border-warning,
    'border-action-hi': $border-action-hi,
    'border-action-low': $border-action-low,
    'bg-tr': $bg-tr,
    'inverted-low': $inverted-low
  ),
  $colors
);

$primary: $first;
$secondary: $gray-100;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-200;
$disabled: $gray-700;
$dark: $gray-800;

$primary-neutral: lighten(#d6daee, 6%);
$secondary-neutral: lighten(#f5f6f7, 6%);
$success-neutral: lighten(#ccf4dc, 6%);
$info-neutral: lighten(#ccf7fa, 6%);
$warning-neutral: lighten(#fff4d8, 5%);
$danger-neutral: lighten(#ffd6db, 6%);
$light-neutral: lighten(#fdfdff, 6%);
$dark-neutral: lighten(#e4e4ea, 6%);
$first-neutral: lighten(#cce9fe, 6%);
$second-neutral: lighten(#d0d2da, 6%);

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'gray': $gray-100,
    'dark': $dark,
    'first': $blue,
    'second': $second,
    'disabled': $disabled
  ),
  $theme-colors
);

$theme-colors-neutral: ();
$theme-colors-neutral: map-merge(
  (
    'primary': $primary-neutral,
    'secondary': $secondary-neutral,
    'success': $success-neutral,
    'info': $info-neutral,
    'warning': $warning-neutral,
    'danger': $danger-neutral,
    'dark': $dark-neutral,
    'first': $first-neutral,
    'second': $second-neutral
  ),
  $theme-colors-neutral
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: true;
$enable-hover-media-query: false; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: false;
$enable-responsive-font-sizes: false;
$enable-validation-icons: true;
$enable-deprecation-messages: false;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $text-body;

// Links
//
// Style anchor elements.

$link-color: $text-brand;
$link-decoration: none;
$link-hover-color: $text-brand;
$link-hover-decoration: none;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15%;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 900px,
  lg: 1200px,
  xl: 1536px
);

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 40px;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-300;

$border-radius: 0.5rem;
$border-radius-lg: 0.75rem;
$border-radius-sm: 0.29rem;
$border-radius-xs: 0.2rem;

$rounded-pill: 50rem;

$box-shadow-sm:
  0 0.313rem 0.8rem rgba($gray-800, 0.5),
  0 0.126rem 0.225rem rgba($gray-800, 0.3);
$box-shadow-sm-light:
  0 0.46875rem 2.1875rem rgba($gray-900, 0.03),
  0 0.9375rem 1.40625rem rgba($gray-900, 0.03),
  0 0.25rem 0.53125rem rgba($gray-900, 0.05),
  0 0.125rem 0.1875rem rgba($gray-900, 0.03);
$box-shadow-sm-light-up:
  0 -0.46875rem 2.1875rem rgba($gray-900, 0.03),
  0 -0.9375rem 1.40625rem rgba($gray-900, 0.03),
  0 -0.25rem 0.53125rem rgba($gray-900, 0.05),
  0 -0.125rem 0.1875rem rgba($gray-900, 0.03);
$box-shadow-sm-above:
  0 -0.313rem 0.8rem rgba($gray-800, 0.5),
  0 -0.126rem 0.225rem rgba($gray-800, 0.3);
$box-shadow: 0 0.5rem 1rem rgba($black, 0.55);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.575);
$box-shadow-sm-dark:
  0 0.313rem 0.8rem rgba($black, 0.3),
  0 0.126rem 0.225rem rgba($black, 0.1);
$box-shadow-xxl:
  0 0.18rem 0.3rem rgba($gray-700, 0.5),
  0 0.326rem 3rem rgba($gray-800, 0.3);

$component-active-color: $white;
$component-active-bg: theme-color('primary');

$caret-width: 0.3em;
$caret-vertical-align: $caret-width * 0.85;
$caret-spacing: $caret-width * 0.85;

$transition-base: all 0.2s ease-in-out;
$transition-fade: opacity 0.15s linear;
$transition-collapse: height 0.35s ease;

$embed-responsive-aspect-ratios: ();
// stylelint-disable-next-line scss/dollar-variable-default
$embed-responsive-aspect-ratios: join(
  ((21 9), (16 9), (4 3), (1 1)),
  $embed-responsive-aspect-ratios
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Cabin', 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-family-monospace: 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 0.85rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base;
$font-size-xl: $font-size-base * 1.35;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: null;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: null;

$display1-size: 3.5rem;
$display2-size: 3rem;
$display3-size: 2.5rem;
$display4-size: 1.7rem;

$display1-weight: 300;
$display2-weight: 300;
$display3-weight: 300;
$display4-weight: 300;
$display-line-height: $headings-line-height;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-600;

$blockquote-small-color: $gray-600;
$blockquote-small-font-size: $small-font-size;
$blockquote-font-size: $font-size-base * 1.25;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25);
$nested-kbd-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.75rem;
$table-cell-padding-sm: 0.3rem;

$table-color: $body-color;
$table-bg: null;
$table-accent-bg: desaturate($gray-100, 30%);
$table-hover-color: $table-color;
$table-hover-bg: desaturate($gray-100, 30%);
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: darken($border-color, 5%);

$table-head-bg-1: rgba($gray-300, 0.7);
$table-head-bg: desaturate($table-head-bg-1, 5%);
$table-head-color: $gray-900;

$table-dark-color: $white;
$table-dark-bg: $gray-900;
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-color: $table-dark-color;
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: darken($gray-900, 10%);
$table-dark-color: $white;

$table-striped-order: odd;

$table-caption-color: $text-muted;

$table-bg-level: -9;
$table-border-level: -6;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.53rem;
$input-btn-padding-x: 1.3rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: $gray-300;
$input-btn-focus-box-shadow:
  0 0.313rem 0.719rem rgba($gray-800, 0.1),
  0 0.156rem 0.125rem rgba($gray-800, 0.06);

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 1.1rem;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.84rem;
$input-btn-padding-x-lg: 1.8rem;
$input-btn-font-size-lg: $font-size-base;
$input-btn-line-height-lg: $line-height-base;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width * 2;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow:
  inset 0 1px 0 rgba($white, 0.15),
  0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$btn-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

// Forms

$label-margin-bottom: 0.5rem;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: $gray-300;

$input-color: $gray-900;
$input-border-color: $gray-600;
$input-border-width: $input-btn-border-width;
$input-box-shadow: inset 0 5px 4px rgba(#333, 0.75);

$input-border-radius: $border-radius-sm;
$input-border-radius-lg: $border-radius-sm;
$input-border-radius-sm: $border-radius-sm;

$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($component-active-bg, 25%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-600;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height-inner-half: calc(#{$input-line-height * 0.5em} + #{$input-padding-y});
$input-height-inner-quarter: calc(#{$input-line-height * 0.25em} + #{$input-padding-y / 2});

$input-height: calc(
  #{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border}
);
$input-height-sm: calc(
  #{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border}
);
$input-height-lg: calc(
  #{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border}
);

$input-transition:
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$form-text-margin-top: 0.25rem;

$form-check-input-gutter: 1.25rem;
$form-check-input-margin-y: 0.3rem;
$form-check-input-margin-x: 0.25rem;

$form-check-inline-margin-x: 0.75rem;
$form-check-inline-input-margin-x: 0.3125rem;

$form-grid-gutter-width: 10px;
$form-group-margin-bottom: 1rem;

$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-100;
$input-group-addon-border-color: $input-border-color;

$custom-forms-transition:
  background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$custom-control-gutter: 0.8rem;
$custom-control-spacer-x: 1.2rem;

$custom-control-indicator-size: 1.3rem;
$custom-control-indicator-bg: $input-bg;

$custom-control-indicator-bg-size: 50% 50%;
$custom-control-indicator-box-shadow: $input-box-shadow;
$custom-control-indicator-border-color: $gray-600;
$custom-control-indicator-border-width: $input-border-width;

$custom-control-indicator-disabled-bg: $input-disabled-bg;
$custom-control-label-disabled-color: $gray-600;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-disabled-bg: rgba(theme-color('primary'), 0.5);
$custom-control-indicator-checked-box-shadow: none;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow;
$custom-control-indicator-focus-border-color: $input-focus-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: lighten($component-active-bg, 35%);
$custom-control-indicator-active-box-shadow: none;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg;

$custom-checkbox-indicator-border-radius: $border-radius-sm;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-checkbox-indicator-indeterminate-box-shadow: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-border-radius: 50%;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$custom-switch-width: $custom-control-indicator-size * 2;
$custom-switch-indicator-border-radius: $custom-control-indicator-size / 2;
$custom-switch-indicator-size: calc(
  #{$custom-control-indicator-size} - #{$custom-control-indicator-border-width * 4}
);

$custom-select-padding-y: $input-padding-y;
$custom-select-padding-x: $input-padding-x;
$custom-select-font-family: $input-font-family;
$custom-select-font-size: $input-font-size;
$custom-select-height: $input-height;
$custom-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$custom-select-font-weight: $input-font-weight;
$custom-select-line-height: $input-line-height;
$custom-select-color: $input-color;
$custom-select-disabled-color: $gray-600;
$custom-select-bg: $input-bg;
$custom-select-disabled-bg: $gray-200;
$custom-select-bg-size: 8px 10px; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$custom-select-background: $custom-select-indicator no-repeat right $custom-select-padding-x center /
  $custom-select-bg-size; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

$custom-select-feedback-icon-padding-right: calc(
  (1em + #{2 * $custom-select-padding-y}) * 3 / 4 + #{$custom-select-padding-x +
    $custom-select-indicator-padding}
);
$custom-select-feedback-icon-position: center right
  ($custom-select-padding-x + $custom-select-indicator-padding);
$custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half;

$custom-select-border-width: $input-border-width;
$custom-select-border-color: $input-border-color;
$custom-select-border-radius: $border-radius-sm;
$custom-select-box-shadow: $input-box-shadow;

$custom-select-focus-border-color: $input-focus-border-color;
$custom-select-focus-width: $input-focus-width;
$custom-select-focus-box-shadow: $input-btn-focus-box-shadow;

$custom-select-padding-y-sm: $input-padding-y-sm;
$custom-select-padding-x-sm: $input-padding-x-sm;
$custom-select-font-size-sm: $input-font-size-sm;
$custom-select-height-sm: $input-height-sm;

$custom-select-padding-y-lg: $input-padding-y-lg;
$custom-select-padding-x-lg: $input-padding-x-lg;
$custom-select-font-size-lg: $input-font-size-lg;
$custom-select-height-lg: $input-height-lg;

$custom-range-track-width: 100%;
$custom-range-track-height: 0.5rem;
$custom-range-track-cursor: pointer;
$custom-range-track-bg: $gray-300;
$custom-range-track-border-radius: 1rem;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);

$custom-range-thumb-width: 1rem;
$custom-range-thumb-height: $custom-range-thumb-width;
$custom-range-thumb-bg: $component-active-bg;
$custom-range-thumb-border: 0;
$custom-range-thumb-border-radius: 1rem;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$custom-range-thumb-focus-box-shadow:
  0 0 0 1px $body-bg,
  $input-focus-box-shadow;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%);
$custom-range-thumb-disabled-bg: $gray-500;

$custom-file-height: $input-height;
$custom-file-height-inner: $input-height-inner;
$custom-file-focus-border-color: $input-focus-border-color;
$custom-file-focus-box-shadow: $input-focus-box-shadow;
$custom-file-disabled-bg: $input-disabled-bg;

$custom-file-padding-y: $input-padding-y;
$custom-file-padding-x: $input-padding-x;
$custom-file-line-height: $input-line-height;
$custom-file-font-family: $input-font-family;
$custom-file-font-weight: $input-font-weight;
$custom-file-color: $input-color;
$custom-file-bg: $input-bg;
$custom-file-border-width: $input-border-width;
$custom-file-border-color: $input-border-color;
$custom-file-border-radius: $input-border-radius;
$custom-file-box-shadow: $input-box-shadow;
$custom-file-button-color: $custom-file-color;
$custom-file-button-bg: $input-group-addon-bg;
$custom-file-text: (
  en: 'Browse'
);

// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $small-font-size;
$form-feedback-valid-color: theme-color('success');
$form-feedback-invalid-color: theme-color('danger');

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"),
  '#',
  '%23'
);

$form-validation-states: ();
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    'valid': (
      'color': $form-feedback-valid-color,
      'icon': $form-feedback-icon-valid
    ),
    'invalid': (
      'color': $form-feedback-invalid-color,
      'icon': $form-feedback-icon-invalid
    )
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1300;
$zindex-sticky: 1320;
$zindex-fixed: 1330;
$zindex-modal-backdrop: 1340;
$zindex-modal: 1350;
$zindex-popover: 1360;
$zindex-tooltip: 1870;

// Navs

$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $gray-600;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius-sm;
$nav-tabs-link-hover-border-color: $gray-600 $gray-600 $nav-tabs-border-color;
$nav-tabs-link-active-color: $black;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $gray-600 $gray-600 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius-sm;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$nav-divider-color: $gray-300;
$nav-divider-margin-y: $spacer / 2;

// Navbar

$navbar-padding-y: $spacer / 2;
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem;

$navbar-brand-font-size: $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2;

$navbar-toggler-padding-y: 0.25rem;
$navbar-toggler-padding-x: 0.75rem;
$navbar-toggler-font-size: $font-size-lg;
$navbar-toggler-border-radius: $btn-border-radius;

$navbar-dark-color: rgba($white, 0.5);
$navbar-dark-hover-color: rgba($white, 0.75);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$navbar-dark-toggler-border-color: rgba($white, 0.1);

$navbar-light-color: rgba($black, 0.5);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-light-brand-color: $navbar-light-active-color;
$navbar-light-brand-hover-color: $navbar-light-active-color;
$navbar-dark-brand-color: $navbar-dark-active-color;
$navbar-dark-brand-hover-color: $navbar-dark-active-color;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 11rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.325rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius-sm;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
$dropdown-divider-bg: $gray-300;
$dropdown-divider-margin-y: $nav-divider-margin-y;
$dropdown-box-shadow: $box-shadow-sm;
$dropdown-box-shadow-above: $box-shadow-sm-above;

$dropdown-link-color: $gray-800;
$dropdown-link-hover-color: $black;
$dropdown-link-hover-bg: $gray-200;

$dropdown-link-active-color: darken($black, 15%);
$dropdown-link-active-bg: $gray-300;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-padding-y: 0.55rem;
$dropdown-item-padding-x: 1.5rem;

$dropdown-header-color: $gray-600;

// Pagination

$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;
$pagination-line-height: 1.25;

$pagination-color: $gray-900;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $gray-400;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-100;
$pagination-hover-border-color: $primary;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

// Jumbotron

$jumbotron-padding: 2rem;
$jumbotron-color: null;
$jumbotron-bg: $gray-200;

// Cards

$card-spacer-y: 16px;
$card-spacer-x: 16px;
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-border-color: rgba($dark, 0.3);
$card-inner-border-radius: $card-border-radius;
$card-cap-bg: $white;
$card-cap-color: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: $grid-gutter-width / 2;
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius-sm;
$tooltip-opacity: 0.85;
$tooltip-padding-y: 0.55rem;
$tooltip-padding-x: 1rem;
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: $line-height-base;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Popovers

$popover-font-size: $font-size-base;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: rgba($black, 0.2);
$popover-border-radius: $border-radius-sm;
$popover-box-shadow: $box-shadow-lg;

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.85rem;
$popover-header-padding-x: 1rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Toasts

$toast-max-width: 350px;
$toast-padding-x: 0.85rem;
$toast-padding-y: 0.45rem;
$toast-font-size: 0.875rem;
$toast-color: null;
$toast-background-color: rgba($white, 0.85);
$toast-border-width: 1px;
$toast-border-color: rgba(0, 0, 0, 0.1);
$toast-border-radius: $border-radius-sm;
$toast-box-shadow: $box-shadow-sm;

$toast-header-color: $gray-800;
$toast-header-background-color: rgba($white, 0.85);
$toast-header-border-color: rgba(0, 0, 0, 0.05);

// Badges

$badge-font-size: 70%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0;
$badge-padding-x: 0.7em;
$badge-border-radius: $border-radius-xs;

$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;

$badge-pill-padding-x: 0.85em;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: rgba($black, 0.5);
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs:
  0rem 5rem 14rem 0 rgba(255, 255, 255, 0.3),
  0 0.8rem 2.3rem rgba(0, 0, 0, 0.6),
  0 0.2rem 0.3rem rgba(0, 0, 0, 0.45);
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.8;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: 1rem;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-xl: 1140px;
$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 300px;

$modal-fade-transform: translate(0, -50px), scale(0.8);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

// Progress bars

$progress-height: 1rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $gray-200;
$progress-border-radius: $border-radius-xs;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1);
$progress-bar-color: $white;
$progress-bar-bg: theme-color('primary');
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

// List group

$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: rgba($black, 0.125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: 0.75rem;
$list-group-item-padding-x: 1.25rem;

$list-group-hover-bg: $gray-200;
$list-group-active-color: darken($black, 15%);
$list-group-active-bg: $gray-300;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-800;
$list-group-action-hover-color: $black;

$list-group-action-active-color: darken($black, 15%);
$list-group-action-active-bg: $gray-300;

// Image thumbnails

$thumbnail-padding: 0.25rem;
$thumbnail-bg: $body-bg;
$thumbnail-border-width: $border-width;
$thumbnail-border-color: $gray-300;
$thumbnail-border-radius: $border-radius;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075);

// Figures

$figure-caption-font-size: 90%;
$figure-caption-color: $gray-600;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: quote('/');

$breadcrumb-border-radius: $border-radius;

// Carousel

$carousel-control-color: $white;
$carousel-control-width: 15%;
$carousel-control-opacity: 0.5;
$carousel-control-hover-opacity: 0.9;
$carousel-control-transition: opacity 0.15s ease;

$carousel-indicator-width: 30px;
$carousel-indicator-height: 3px;
$carousel-indicator-hit-area-height: 10px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: $white;
$carousel-indicator-transition: opacity 0.6s ease;

$carousel-caption-width: 70%;
$carousel-caption-color: $white;

$carousel-control-icon-width: 20px;

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"),
  '#',
  '%23'
);

$carousel-transition-duration: 0.6s;
$carousel-transition: transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width: 2rem;
$spinner-height: $spinner-width;
$spinner-border-width: 0.25em;

$spinner-width-sm: 1rem;
$spinner-height-sm: $spinner-width-sm;
$spinner-border-width-sm: 0.2em;

// Close

$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;

// Code

$code-font-size: 87.5%;

$kbd-padding-y: 0.2rem;
$kbd-padding-x: 0.4rem;
$kbd-font-size: $code-font-size;
$kbd-color: $white;
$kbd-bg: $gray-900;

$pre-color: $gray-900;
$pre-scrollable-max-height: 340px;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$overflows: auto, hidden;
$positions: static, relative, absolute, fixed, sticky;

// Printing

$print-page-size: a3;
$print-body-min-width: map-get($grid-breakpoints, 'lg');

// Custom variables

// General Variables

$layout-spacer: $spacer;

// Box shadow

$box-shadow-layout-bottom:
  0 0.46875rem 2.1875rem rgba($gray-900, 0.03),
  0 0.9375rem 1.40625rem rgba($gray-900, 0.03),
  0 0.25rem 0.53125rem rgba($gray-900, 0.05),
  0 0.125rem 0.1875rem rgba($gray-900, 0.03);

$box-shadow-layout-top:
  0 -0.46875rem 2.1875rem rgba($gray-900, 0.03),
  0 -0.9375rem 1.40625rem rgba($gray-900, 0.03),
  0 -0.25rem 0.53125rem rgba($gray-900, 0.05),
  0 -0.125rem 0.1875rem rgba($gray-900, 0.03);

$box-shadow-layout-right:
  0.46875rem 0 2.1875rem rgba($gray-900, 0.03),
  0.9375rem 0 1.40625rem rgba($gray-900, 0.03),
  0.25rem 0 0.53125rem rgba($gray-900, 0.05),
  0.125rem 0 0.1875rem rgba($gray-900, 0.03);

$box-shadow-layout-left:
  -0.46875rem 0 2.1875rem rgba($gray-900, 0.03),
  -0.9375rem 0 1.40625rem rgba($gray-900, 0.03),
  -0.25rem 0 0.53125rem rgba($gray-900, 0.05),
  -0.125rem 0 0.1875rem rgba($gray-900, 0.03);

// Cet import sert aux menus d'actions (exemple : bouton d'action de la liste des utilisateurs)
@import '../components/NavigationMenus/NavigationMenusVerticalBasic/variables';
