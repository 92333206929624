// Enlever la marge des InfoWindow dans Google Map
// SOURCE: https://github.com/visgl/react-google-maps/issues/378#issuecomment-2141560117

.gm-style-iw-chr {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  left: 12px;
}

.gm-style-iw.gm-style-iw-c {
  padding-top: 14px !important;
}
